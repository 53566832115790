/*============================================================================
	#Header
==============================================================================*/

.header {
    max-width: 1920px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 88;
    background-color: #fff;

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 42px;
        background-color: #4A5668;
    }

    &__content {
        display: flex;
        flex-direction: column;
        // position: relative;
    }

    &__header-top {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        background-color: #fff;
    }

    &__header-bottom {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 42px;
        align-items: center;
        border-top: 1px solid #E3E6F0;
    }
}

.header-top {
    &__left-side {
        display: flex;
        align-items: center;
    }

    &__logo {
        margin: 0 25px 0 0;
    }

    &__contacts {
        margin: 0 25px 0 0;
    }

    &__right-side {
        display: flex;
        align-items: center;
    }

    &__icon-shopping {
        display: none;
    }

    &__icon-burger {
        display: none;
    }

    &__item {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin: 0 25px 0 0;

        a {
            color: #5F5F5F;
            transition: 0.5s;

            &:hover {
                color: #e60003;
            }
        }

        &_language {
            margin: 0;

            a {
                font-size: 12px;
                color: #ABABAB;
                transition: 0.5s;

                &:hover {
                    color: #25282E;
                }
            }
        }

        .active {
            a {
                color: #e60003;
            }
        }

        &:last-child {
            content: '';
            margin: 0;
        }
    }
}

.button {
    &__link {
        border: 1px solid #e60003;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #e60003;
        padding: 5px 15px;
    }
}

.t-phone {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5F5F5F;

    &:hover {
        color: #e60003;
    }
}

.cont-schedule {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #5F5F5F;
}

.header-bottom {
    &__left-side {
        position: relative;
        z-index: 2;
        display: flex;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }

    &__bottom-item {
        margin: 0 33px 0 0;

        &:first-child {
            position: relative;

            a {
                padding: 0 0 0 30px;
                position: relative;
                z-index: 3;
            }

            &:before {
                content: '';
                position: absolute;
                top: 12px;
                left: 20px;
                height: 18px;
                width: 18px;
                background-image: url(/assets/praktyk/icons/square-points.svg);
            }
        }

        &:last-child {
            margin: 0;
        }

        a {
            color: #FFFFFF;
        }
    }

    &__right-side {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    .search-button {
        &__search-style {
            position: absolute;
            right: 0;
            border-radius: 16px;
            height: 30px;
            background-color: #4A5668;
            border: 1px solid #808995;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #fff;
            padding: 0 15px 0 13px;
            width: 0;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
    }


    .bottom-side {
        display: none;
        @media(max-width: 1024px) {
            display: block;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #5F5F5F;
            order: 3;
            background-color: #F6F8FF;
            width: 100%;
            text-align: center;
            padding-bottom: 60px;
            a {
                font-size: 13px;
                line-height: 18px;
                color: #5F5F5F;
            }
            &__shop-info {
                width: 400px;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;

                &:first-child {
                    padding: 35px 0 5px 0;
                }

                &:last-child {
                    padding: 10px 0 35px 0;
                }
                //span{
                //    width: 33%;
                //}
            }
        }
    }


    &__icon {
        margin: 0 35px 0 0;
        position: relative;

        a {
            position: relative;
            z-index: 3;
        }

        &:last-child {
            content: '';
            margin: 0;
        }

        span {
            position: absolute;
            left: 20px;
            bottom: 15px;
            border-radius: 50%;
            background-color: #e60003;
            z-index: 3;
            width: 16px;
            height: 16px;

            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
            line-height: 16px;
        }
    }

    &__icon-dark {
        display: none;
    }

    &__dark-search-button {
        display: none;
    }
}

.bottom-side-contacts {
    display: none;
}

.header-bottom__left-side .header-bottom__bottom-item .mobile-arrow {
    display: none;
}

.header-menu__link--mobile {
    display: none;
}

.header-bottom__dark-search-button input:active, .header-bottom__dark-search-button input:focus,
.header-bottom .search-button__search-style:active, .header-bottom .search-button__search-style:focus {
    box-shadow: none !important;
}
.header-down #header-menu-button:hover #header-menu-other{
    height: auto;
}
#header-menu-other.open{
    display: block;
}
.inside-menu__list::-webkit-scrollbar {
    width: 8px;
}
.inside-menu__list::-webkit-scrollbar-track {
    background: #E5E8F4;
}
.inside-menu__list::-webkit-scrollbar-thumb {
    background: rgba(74, 86, 104, 0.4);
}
#header-menu-other .inside-menu::-webkit-scrollbar {
    width: 8px;
}
#header-menu-other{
  .header-menu__overlay{
        width: 100%;
        height: 100vh;
        background: rgba(51, 69, 115, 0.3);
        position: fixed;
        bottom: 0;
        left: 0;
        -webkit-transition: bottom .3s ease-in-out;
        transition: bottom .3s ease-in-out;
        z-index: -1;
        top: 67px;
        right: 0;
    }
}
.header-down{
    #header-menu-other{
        .header-menu__overlay{
            width: 100%;
            height: 100vh;
            background: rgba(51, 69, 115, 0.3);
            position: fixed;
            bottom: 0;
            left: 0;
            -webkit-transition: bottom .3s ease-in-out;
            transition: bottom .3s ease-in-out;
            z-index: -1;
            top: 39px;
            right: 0;
        }
    }
}
.header-up{
    #header-menu-other{
        .header-menu__overlay{
            width: 100%;
            height: 100vh;
            background: rgba(51, 69, 115, 0.3);
            position: fixed;
            bottom: 0;
            left: 0;
            -webkit-transition: bottom .3s ease-in-out;
            transition: bottom .3s ease-in-out;
            z-index: -1;
            top: 109px;
            right: 0;
        }
    }
}

.no-scroll-popup .header {
    padding-right: 0;
}


.header-menu__overlay{
    width: 100%;
    height: 100vh;
    background: rgba(51, 69, 115, 0.3);
    position: fixed;
    bottom: 0;
    left: 0;

    -webkit-transition: bottom .3s ease-in-out;
    transition: bottom .3s ease-in-out;
    z-index: 5555;
    top: 0;
    right: 0;
    @media(max-width: 1199px) {
        display: none;
    }
}

.open .header-menu__overlay{
    top: 0;
    bottom: auto;
    top: 100%;
    -webkit-transition: top .2s ease-in-out,right .2s ease-in-out;
    transition: top .2s ease-in-out,right .2s ease-in-out;
}
html{
    //margin-left: calc(100vw - 100%);
    //margin-right: 0;
    //overflow-x: hidden;
    //margin-right: calc(-1 * (100vw - 100%));
}
html.no-scroll{
overflow-y: none;
}

#header-menu-other .inside-menu::-webkit-scrollbar-track {
    background: #E5E8F4;
}

#header-menu-other .inside-menu::-webkit-scrollbar-thumb {
    background: rgba(74, 86, 104, 0.4);
}


@media(max-width: 1199px) {
    .header__header-menu.header-menu .header-menu__item.akcyi a {
        color: #e60003;
    }
    .header__header-menu.header-menu .header-menu__item.akcyi .header-menu__icon .icon-svg {
        fill: #e60003;
    }

    .header-bottom__left-side .header-bottom__bottom-item a.header-menu__link--mobile {
        display: block;
    }
    .header-bottom__left-side .header-bottom__bottom-item a.header-menu__link--desktop {
        display: none;
    }
    .header-menu__item.mob-active {
        background: #e60003;
        .header-menu__icon {
          img.hover{
              display: inline-block;
          }
            img.active{
                display: none;
            }
        }

        a {
            color: #fff;
        }

        a:before {
            z-index: -1;
        }

    }
    .header-menu__item.mob-active .icon-svg {
        fill: #fff;
    }

    .header-bottom__left-side .header-bottom__bottom-item .mobile-arrow {
        display: block;
        width: 8px;
        height: 16px;
        fill: #D0D0D0;
        position: absolute;
        top: 12px;
        right: 0;
    }
    .bottom-side-contacts__item_language.active a {
        color: #e60003;
    }
    .bottom-side-contacts__item .bottom-side-contacts__item_language:last-child {
        padding-right: 0;

    }
    .bottom-side-contacts {
        order: 1;
    }
    .header {
        border-bottom: 1px solid #E3E6F0;

        &:after {
            display: none;
        }

        &__header-bottom {
            display: none;
            flex-direction: column;
            background-color: #f6f8ff;
            height: 100vh;
            overflow: auto;
            justify-content: flex-start;
            position: absolute;
            top: 67px;
            // top: -600px;
            right: 0;
            width: 100%;
            z-index: 6;
        }
    }
    .header-bottom__left-side {
        margin: 0;
        padding-top: 35px;
        width: 100%;
        background: #f6f8ff;
    }
    .header-bottom__bottom-item {
        max-width: 400px;
        margin: 0 auto;
    }
    .header-top {
        &__logo {
            margin: 0;
        }

        &__contacts {
            display: none;
        }

        &__schedule {
            display: none;
        }

        &__button {
            display: none;
        }

        &__right-side {
            display: none;
        }

        &__icon-shopping {
            display: block;
            position: relative;
            width: 26px;
            height: 26px;

            a {
                position: relative;
                z-index: 3;
            }

            span {
                position: absolute;
                left: 20px;
                bottom: 15px;
                border-radius: 50%;
                background-color: #e60003;
                z-index: 3;
                width: 16px;
                height: 16px;

                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                color: #FFFFFF;
                text-align: center;
                line-height: 16px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 68px;
                background-color: #E3E6F0;
                left: -20px;
            }
        }

        &__icon-burger {
            display: block;
            position: relative;
            width: 27px;
            height: 20px;
            cursor: pointer;

            span {
                transition: all 0.3s ease 0s;

                &:first-child {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 15px;
                    height: 2px;
                    background-color: #4A5668;
                    border-radius: 1px;
                }

                &:last-child {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 15px;
                    height: 2px;
                    background-color: #4A5668;
                    border-radius: 1px;
                }

                &:nth-child(2) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 2px;
                    background-color: #4A5668;
                    border-radius: 1px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 68px;
                background-color: #E3E6F0;
                right: -20px;
            }
        }

        .icon-burger-active {
            span {
                &:first-child {
                    width: 100%;
                    top: 9px;
                    transform: rotate(45deg);
                }

                &:last-child {
                    bottom: 9px;
                    transform: rotate(-45deg);
                    width: 100%;
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }

    .header-bottom {
        &__left-side {
            flex-direction: column;
            order: 2;
            position: relative;
            border-bottom: 1px solid #E3E6F0;
        }

        &__bottom-item {
            width: 400px;
            margin: 0 auto 30px auto;

            &:first-child {
                &:before {
                    content: '';
                    background-image: url(/assets/praktyk/icons/dark-dots.svg);
                }
            }

            a {
                color: #25282E;
            }

            &:last-child {
                margin: 0 auto 35px auto;
            }
        }

        &__right-side {
            order: 1;
            width: 100%;
            justify-content: center;
            border-bottom: 1px solid #E3E6F0;
            background: #F6F8FF;
        }

        &__icon {
            display: none;
        }

        &__icon-dark {
            display: block;
            position: relative;

            a {
                position: relative;
                z-index: 3;
            }

            span {
                position: absolute;
                left: 20px;
                bottom: 15px;
                border-radius: 50%;
                background-color: #e60003;
                z-index: 3;
                width: 16px;
                height: 16px;

                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #FFFFFF;
                text-align: center;
                line-height: 16px;
            }
        }

        &__dark-search-button {
            display: block;
            position: relative;
            margin: 25px 40px 25px 0;

            a {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
            }
        }

        &__dark-search-style {
            right: 0;
            border-radius: 16px;
            height: 30px;
            background-color: #F6F8FF;
            border: 1px solid #E3E6F0;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #5f5f5f;
            padding: 0 35px 0 13px;
            width: 275px;
            position: relative;
        }
    }

    .bottom-side-contacts {
        display: flex;
        padding: 35px 0 100px 0;
        background-color: white;
        width: 100%;
        justify-content: center;

        &__column {
        }

        &__phone {
        }

        &__button {
            padding: 25px 0 0 0;
        }

        &__schedule {
            padding: 7px 0 0 0;
            text-align: right;
        }

        &__item {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            justify-content: flex-end;
            padding: 0;

            &_language {
                padding: 14px 30px 0 0;

                a {
                    color: #ABABAB;

                    &:hover {
                        color: #e60003;
                    }
                }

                &:first-child {
                    padding: 14px 30px 0 0;
                }
            }
        }
    }
    .header-menu {
        position: fixed;
        height: 100vh;
        overflow: auto;
    }
    .bottom-side-contacts__column{
        width: 200px;
    }
}
#header-menu-other {
    border-right: 1px solid #E3E6F0;
}
@media(max-width: 375px) {
    .header-bottom {
        &__dark-search-button {
            margin: 25px 20px 25px 0;
        }

        &__dark-search-style {
            width: 240px;
        }
    }
}

/* header-menu=================== */
.header-menu {
    display: none;
    position: absolute;
    left: -33px;
    top: 41px;
    background-color: #F6F8FF;

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 28px;

        .icon-svg {
            width: 20px;
            height: 20px;
            fill: rgba(0, 0, 0, 1);
        }

    }

    &__arrow {
        width: 8px;
        height: 16px;
        right: 20px;
        position: absolute;
        margin-top: 2px;

        .icon-svg {
            width: 8px;
            height: 16px;
            fill: #530F0F;
        }
    }

    &__item {
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #25282E;
        padding: 0 0 0 33px;
        width: 320px;
        cursor: pointer;
        display: flex;
        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                background-color: #e60003;
                color: #fff;

                a {
                    &:before {
                        content: '';
                        display: none;
                    }
                }

                .header-menu__icon {
                    .icon-svg {
                        fill: #fff;
                    }
                }
            }
        }

        &:first-child {
            background: #fff;

            a {
                color: #e60003;
            }

            .icon-svg {
                fill: #e60003;
            }

            @media (max-width: 2500.98px) and (min-width: 1024.98px) {
                &:hover {
                    background-color: #e60003;
                    color: #fff;
                }
            }

            a:before {
                display: none;
            }
        }

        &:last-child {
            a:before {
                display: none;
            }
        }

        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                .header-menu__arrow .icon-svg {
                    fill: #fff;
                }
            }
        }
    }

    .akcyi {
        .header-menu__icon {
            .icon-svg {
                fill: #530F0F;
            }
        }
    }

    a {
        position: relative;
        padding: 12px 0 14px 0;
        &:before {
            content: '';
            position: absolute;
            left: -26px;
            bottom: 0;
            width: 265px;
            height: 1px;
            background-color: #E3E6F0;
        }
    }

    .inside-menu {
        top: 0;
        padding: 0 20px;
    }

}

.active-style {
    display: block;
    width: 355px;

}

.header-bottom .search-button__search-style.active-style {
    display: block;
    width: 200px;
    padding-right: 40px;
}

.header-menu a, .inside-menu__item a {
    font-size: 14px;
    line-height: 17px;
    color: #25282E;
    transition: 0.1s;
    display: block;
    width: 100%;
}

@media (max-width: 2500.98px) and (min-width: 1024.98px) {
    .header-menu__item:hover a {
        color: #fff;
    }
}

.header-menu__item .inside-menu__item a {
    color: #25282E;
}

.header-menu__item:hover .inside-menu__link-arrow {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #e60003;
    padding-right: 76px;
}

/* ============================================== */

/* inside menu=================================== */

.inside-menu {
    position: absolute;
    left: 321px;
    display: none;
    width: 470px;
    height: 100%;
    background-color: #F6F8FF;
    min-height: 537px;

    &__title {

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #4A5668;
        padding: 13px 0 8px 25px;
        margin: 15px 0;
        position: relative;
        border-bottom: 1px solid #E3E6F0;
    }

    &__item {
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        opacity: 0.8;
        padding: 0 0 0 50px;
        position: relative;

        a {
            color: #25282E;
            padding: 12px 0;
        }

        &:hover {
            a {
                color: #9C220C;
                opacity: 0.8;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 27px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #e60003;
            border-radius: 50%;
            width: 6px;
            height: 6px;
        }

        &:hover {
            color: #e60003;
            background-color: #E5E8F4;
        }
    }

    &__red-arrow {
        position: relative;
        margin: 25px 0 0 175px;

        &:before {
            content: '';
            position: absolute;
            background-image: url(/assets/praktyk/icons/red-long-arrow.svg);
            width: 59px;
            height: 14px;
            top: 28px;
            transform: translateY(-50%);
            left: 180px;
            transition: 0.5s;
        }

        &:hover {
            &:before {
                left: 190px;
            }

        }
    }

    &__link-arrow {

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #e60003;
        padding: 0;
        padding-right: 76px;
    }
}

/*============================================================================
	HEADER
==============================================================================*/
.header-down .header__header-top {
    margin-top: -70px;
}

.header__header-top {
    -webkit-transition: margin .5s ease;
    -o-transition: margin .5s ease;
    transition: margin .5s ease;
}

.header-up .header__header-top {
    margin-top: 0;
}

.search-button__search-styl.active-style:focus::-ms-clear {
    width: 0;
    height: 0;
}

.search-button__search-styl.active-style:focus::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

::-ms-clear {
    display: none;
}

input[type=search]::-ms-clear {
    display: none;
}

/*============================================================================
	HEADER
==============================================================================*/
//.header-bottom__bottom-item.open .header-menu__link {
//    padding: 11px 0 11px 30px;
//}


.header-bottom__left-side .header-bottom__bottom-item a {
    text-transform: uppercase;
}

#header-menu-other {
    height: auto;

    a {
        text-transform: inherit;
        padding: 13px 30px 15px 0;
    }

    .header-menu__item {
        padding: 0 0 0 33px;
    }

    .inside-menu {
        min-height: 528px;
        overflow: auto;
        padding-bottom: 20px;
    }

    .header-menu__item:first-child {
        background: #fff;
    }

    .header-menu__item:first-child:hover {
        background-color: #e60003;
    }

    .header-menu__item:hover .header-menu__icon .icon-svg {
        fill: #fff;
    }
}

.header-bottom__bottom-item:first-child #header-menu-other a {
    //padding: 13px 0 15px 0;
}
.header-bottom__bottom-item  #header-menu-other .inside-menu__red-arrow a.inside-menu__link-arrow{
    padding: 13px 90px 0 0;

}
.header-bottom__bottom-item {
    padding: 12px 20px;
    transition: 0.5s;
    @media (max-width: 1199.98px) {
        padding: 12px 0;
    }

}
//.header-bottom__bottom-item:first-child #header-menu-other a{
//    padding: 0 80px 0 0;
//}
@media (max-width: 1199.98px) {
    .header-bottom__bottom-item:first-child:before {
        left: 0;
    }
}

.modal-trigger {
    transition: 0.5s;
    @media (max-width: 2500.98px) and (min-width: 1024.98px) {
        &:hover {
            background: #9C220C;
            cursor: pointer;
            color: #fff;
        }
    }
}

/* ============================================== */
@media (max-width: 2500.98px) and (min-width: 1199.98px) {
    .header-bottom__bottom-item:hover {
        background: #2B3D57;
    }

    /* ==================== hover inside-menu */
    .header-bottom__bottom-item.open {
        &:hover {
            #header-menu-other {
                display: block;
            }
        }
    }

    .header-menu {
        &__item {
            &:hover {
                .inside-menu {
                    display: block;
                    color: initial;
                }
            }
        }
    }
}

/* ====================================== */

.menu-active {
    display: block;
}

.move-active {
    display: flex;
}

.header-menu__back {
    display: none;
}

.header-menu__icon img.hover {
    display: none;
}

.header-menu__item:hover .header-menu__icon img.hover {
    display: block;
}

.header-menu__item:hover .header-menu__icon img.active {
    display: none;
}

/* header-menu-adaptive */
@media(max-width: 1024px) {
    .header-menu__back {
        display: block;
    }
    .header-menu {
        position: fixed;
        z-index: 55555;
        top: 68px;
        left: 1800px;
        width: 100%;
        display: block;
        padding-top: 30px;
        height: 100vh;
        transition: all 0.5s linear;
        padding-bottom: 100px;

        &__item {
            width: 450px;

            a {
                .header-menu__item {
                    max-width: 470px;
                    margin: 0 auto;
                    position: relative;

                    a {
                        font-size: 14px;
                        line-height: 17px;
                        color: #25282E;
                    }
                }

                &:before {
                    content: '';
                    width: 395px;
                }
            }
        }
    }
    .header-bottom {
        display: flex;
        left: -2000px;
        transition: 0.5s;
        overflow: scroll;
        height: 100vh;
        //-webkit-transition: -webkit-transform .3s ease-in-out;
        //-o-transition: -o-transform .3s ease-in-out;
        //-o-transition: transform .3s ease-in-out;
        //transition: -webkit-transform .3s ease-in-out;
        //transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        //-webkit-transform: translateX(-200%);
        //-ms-transform: translateX(-200%);
        //transform: translateX(-200%);
    }

    .header-menu__back img {
        transform: rotate(-180deg);
        margin-right: 20px;
        margin-top: 2px;
        width: 60px;
    }

    .header-bottom.move-active {
        //left: 0;
        //padding-bottom: 80px;
        animation-delay: 0.5s;
        //position: absolute;
        //z-index: 10000;
        //width: 100%;
        //height: 100%;
        //background-color: #fff;
        transition: transform .5s;
        //overflow-y: auto;
        //min-height: 100vh;


        background: #F6F8FF;
        display: flex;
        min-height: calc(100vh - 67px);
        bottom: 0;
        position: absolute;
        left: 0;
        z-index: 10000;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-bottom: 50px;


    }
    .header-menu__back {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #898989;
        padding-left: 35px;
        max-width: 470px;
        margin: 0 auto 25px;
    }

    .header-menu__item {
        max-width: 470px;
        margin: 0 auto;
        position: relative;
    }
    .header-menu.menu-active {
        left: 0;
        transition: 0.5s;
    }

    .bottom-side-contacts {
        padding: 35px 0 35px 0;
        order: 3;
    }
    .header {
        z-index: 77;
    }
    .header-bottom.move-active.right-move-active {
        left: 0;
        transition: 1s;
        animation-delay: 0.5s;
    }
    .bottom-side-contacts{
        padding-bottom: 80px !important;
    }

}

@media(max-width: 480px) {
    .header-bottom__bottom-item, .header-bottom .bottom-side__shop-info {
        width: 300px;
    }
    .bottom-side-contacts__column{
        width: 150px;
    }
    .header-bottom .bottom-side {
        padding-bottom: 0;
    }
    .header-bottom.move-active {
        padding-bottom: 0;
    }
    .header-bottom__bottom-side-contacts.bottom-side-contacts .button__link {
        font-size: 10px;
    }
    .header-bottom__left-side {
        min-height: 330px;
    }
    .header-menu__item {
        max-width: 100%;
    }
    .bottom-side-contacts{
    min-height: 200px;
}
    .header-bottom__right-side{
        min-height: 80px;
    }
    .header-bottom__dark-search-style {
        box-shadow: inherit;
    }
}


