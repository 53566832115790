/*============================================================================
	#Typography
==============================================================================*/
html {
    font-size: 62.5%;
}

body,
button,
input,
select,
optgroup,
textarea {
    font-size: 1.5rem;
    line-height: 1.6;
    font-weight: normal;
    // letter-spacing: 0.025em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

.typography {
    > * :first-child {
        margin-top: 0;
        padding-top: 0;
    }

    h1 {
        font-size: 3.6rem;
        line-height: 1.05;
    }

    h2 {
        font-size: 3.2rem;
        line-height: 1.25;
    }

    h3 {
        font-size: 2.6rem;
        line-height: 1.25;
    }

    h4 {
        font-size: 2.0rem;
        line-height: 1.22;
    }

    h5 {
        font-size: 1.8rem;
        line-height: 1.22;
    }

    h6 {
        font-size: 1.6rem;
        line-height: 1.22;
    }


    strong {
        font-weight: bold;
    }

    a:active,
    a:visited {
        color: orange;
    }

    a {
        color: orange;
        text-decoration: none;
        transition: color 0.2s ease;
        text-decoration: none;

        &:hover {
            color: blue;
            text-decoration: none;
        }
    }

    p {
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin: 0 0 2.5rem 0;
    }


    img {
        margin: 2rem 0 4rem 0;
    }

    hr {
        background-color: #e8f5ff;
        border: 0;
        height: 2px;
        margin: 1.5rem 0 1.5rem 0;
    }

    ul,
    ol {
        list-style: none;
        padding: 0;
        margin: 0 0 2.4rem 0rem;
        line-height: 1.42;
    }

    ul {
        padding-left: 20px;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 8px;

            &::before {
                content: '';
                position: absolute;
            }
        }
    }

    ol {
        li {
            counter-increment: item;
            margin-bottom: 0.5rem;
            position: relative;
            padding-left: 40px;
            margin-bottom: 4px;

            &::before {
                content: counter(item) '.';
                display: inline-block;
                margin-right: 11px;
                color: #222;
                font-weight: bold;
                position: absolute;
                left: 0;
            }
        }
    }

    li > ul,
    li > ol {
        margin-bottom: 0;
        margin-left: 1.5em;
    }

    dt {
        font-weight: bold;
    }

    dd {
        margin: 0 1.5em 1.5em;
    }

    figure {
        margin: 1em 0;
    }

    table {
        margin: 0 0 1.5em;
        width: 100%;
    }

    dfn,
    cite,
    em,
    i {
        font-style: italic;
    }

    blockquote {
        //margin: 0;
        //padding: 4.3rem 0;
        //margin: 6rem 6rem;
        //font-size: 1.6rem;
        //font-weight: bold;
        //position: relative;
        //z-index: 1;
        //border-top: 2px solid orange;

        //&::before {
        //    content: '';
        //    background: #f7f8fa;
        //    top: 0;
        //    left: -130px;
        //    width: calc(100% + 260px);
        //    height: 100%;
        //    position: absolute;
        //    z-index: -1;
        //}

        &::after {
            content: '';
            top: 30px;
            left: 15px;
            position: absolute;
            color: #B2BBC3;
            width: 30px;
            height: 30px;
            font-size: 60px;
            background: url('/assets/praktyk/icons/quote.svg') center / cover no-repeat;
        }

        p {
            margin: 0;
        }
    }

    address {
        margin: 0 0 1.5em;
    }

    pre {
        background: #eee;
        font-family: 'Courier 10 Pitch', Courier, monospace;
        font-size: 15px;
        font-size: 0.9375rem;
        line-height: 1.6;
        margin-bottom: 1.6em;
        max-width: 100%;
        overflow: auto;
        padding: 1.6em;
    }

    code,
    kbd,
    tt,
    var {
        font-family: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono',
        monospace;
        font-size: 15px;
        font-size: 0.9375rem;
    }

    abbr,
    acronym {
        border-bottom: 1px dotted #666;
        cursor: help;
    }

    mark,
    ins {
        background: #fff9c0;
        text-decoration: none;
    }

    big {
        font-size: 125%;
    }

    iframe {
        width: 100%;
        margin: 2rem 0 1.2rem 0;
    }

    .responsive-iframe {
        position: relative;
        display: inline-block;
        padding-top: 52%;
        width: 100%;
        margin: 2rem 0 4rem 0;

        iframe {
            position: absolute;
            width: 90%;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;

        }
    }
}


.h1 {
    font-size: 4.8rem;
    line-height: 1.05;
}

.h2 {
    font-size: 3.6rem;
    line-height: 1.25;
}

.h3 {
    font-size: 2.8rem;
    line-height: 1.25;
}

.h4 {
    font-size: 1.8rem;
    line-height: 1.22;
}

.h5 {
    font-size: 1.6rem;
    line-height: 1.22;
}

.h6 {
    font-size: 1.4rem;
    line-height: 1.22;
}

.seo-text {
    &:first-child, &:last-child{
        margin-bottom: 0;
    }
    h1, h2, h3, h4, h5, h6, a {
        color: #25282E;
    }

    h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #25282E;
        margin-bottom: 40px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 24px;
            line-height: 26px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        @media (max-width: 767.98px) {
            margin-bottom: 25px;
            font-size: 22px;
            line-height: 26px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 15px;
        @media (max-width: 767.98px) {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 15px;
        @media (max-width: 767.98px) {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
        @media (max-width: 767.98px) {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
        @media (max-width: 767.98px) {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 24px;
        }
    }

    p {
        font-size: 1.4rem;
        line-height: 2.6rem;
        margin: 0 0 15px 0;
        @media (max-width: 767.98px) {
            margin-bottom: 10px;
        }

        a {
            background: #E3E6F0;
            font-size: 1.4rem;
            line-height: 2.6rem;
        }
    }

    blockquote {
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
        position: relative;
        line-height: 28px;
        text-align: center;
        padding: 48px 30px;
        border-top: 1px solid #C40A0A;
        margin-top: 50px;

        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 25px 10px;
            margin-top: 30px;
        }

        &::after {
            content: '';
            top: -17px;
            position: absolute;
            height: 35px;
            font-size: 60px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-image: url('../../../assets/praktyk/icons/quote-seo.svg');
            background-position: center;
            background-size: 42px;
            left: 50%;
            transform: translateX(-50%);
            width: 154px;
            @media (max-width: 767.98px) {
                background-size: 30px;
                width: 85px;
            }
        }
    }
    ul {
        margin-bottom: 20px;
        @media (max-width: 767.98px) {
            margin-bottom: 15px;
        }
        li {
            font-weight: 400;
            position: relative;
            font-size: 14px;
            line-height: 28px;
            padding-left: 15px;
            &:before {
                position: absolute;
                top: 10px;
                left: 0;
                width: 6px;
                height: 6px;
                background: #AA2A11;
                content: '';
                border-radius: 50%;
                @media (max-width: 767.98px) {
                    top: 10px;
                }
            }
        }
    }

    ol {
        margin-bottom: 20px;
        padding-left: 15px;
        @media (max-width: 576.98px) {
            margin-bottom: 10px;
        }

        li {
            font-weight: 400;
            position: relative;
            font-size: 14px;
            line-height: 32px;
            padding-left: 0;
            @media (max-width: 767.98px) {
                line-height: 28px;
            }
        }
    }
    p{
        iframe{
            width: 100%;
            height: 400px;
            @media (max-width: 767.98px) {
                height: 250px;
            }
        }
    }
    iframe{
        width: 100%;
        height: 400px;
        @media (max-width: 767.98px) {
            height: 250px;
        }
    }
}

@media (max-width: 767.98px) {
    .typography .responsive-iframe iframe {
        width: 100%;
    }

    .post__typography img {
        padding: 0 !important;
    }


    .typography blockquote::after {
        top: 20px;
        left: 10px;
        width: 20px;
        height: 20px;
    }


    .typography {
        h2 {
            font-size: 2.4rem;
            line-height: 1.25;
        }

        h3 {
            font-size: 2.2rem;
            line-height: 1.25;
        }

        h4 {
            font-size: 2.0rem;
            line-height: 1.22;
        }

    }

}

/* ======= services, delivery styles*/
.services-list {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    li {
        padding: 0 0 10px 0;
        text-indent: 15px;
        position: relative;

        &:last-child {
            padding: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #e60003;
        }
    }
}

/* ======= */

