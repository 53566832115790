/*--------------------------------------------------------------
# Icons
--------------------------------------------------------------*/

.icon__viber {
    fill: #784F98;
}

.icon__whatsapp {
    fill: #3FC451;
}

.icon__telegram {
    fill: #2BA0DA;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: #D0D0D0;
    opacity: 0.4;
}
.swiper-pagination-bullet-active {
    background: #333B47 !important;
    opacity: 0.8 !important;
}
