
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.textarea:-webkit-autofill,
.textarea:-webkit-autofill:hover,
.textarea:-webkit-autofill:focus {
    // border-color: inherit;
    // border: none;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background: #fff;
    transition: none;
}

.input[type="text"],
.input[type="email"],
.input[type="url"],
.input[type="password"],
.input[type="search"],
.input[type="number"],
.input[type="tel"],
.input[type="range"],
.input[type="date"],
.input[type="month"],
.input[type="week"],
.input[type="time"],
.input[type="datetime"],
.input[type="datetime-local"],
.input[type="color"],
.textarea {
    padding: 2px 12px;
    border-radius: 0;
}

.input[type="text"]:focus,
.input[type="email"]:focus,
.input[type="url"]:focus,
.input[type="password"]:focus,
.input[type="search"]:focus,
.input[type="number"]:focus,
.input[type="tel"]:focus,
.input[type="range"]:focus,
.input[type="date"]:focus,
.input[type="month"]:focus,
.input[type="week"]:focus,
.input[type="time"]:focus,
.input[type="datetime"]:focus,
.input[type="datetime-local"]:focus,
.input[type="color"]:focus,
.textarea:focus {
    // border-bottom: 2px solid $blue;
}

.input[type="search"]::-webkit-search-decoration,
.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-results-button,
.input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.input,
.textarea {
    padding: 4px 16px;
    border: 0;
    border-bottom: 2px solid #aec5e0;


    &:focus {

        &::placeholder {
            color: transparent;
        }
    }
}



.input:focus::-webkit-input-placeholder {
    color: transparent;
}

.input:focus:-moz-placeholder {
    color: transparent;
}

.input:focus::-moz-placeholder {
    color: transparent;
}

.input:focus:-ms-input-placeholder {
    color: transparent;
}

/* Remove Number From Inputs */
.input[type=number]::-webkit-inner-spin-button,
.input[type=number]::-webkit-outer-spin-button,
.input[type=number] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
}

input {
    background: #FFFFFF;
    border: 1px solid #E5E8F4;
    box-sizing: border-box;
    padding: 13px 15px 13px 20px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #25282E;
}
input:active, input:focus{
    box-shadow: 0px 0px 8px #C2E3BA;
}
textarea{
    background: #FFFFFF;
    border: 1px solid #E5E8F4;
    box-sizing: border-box;
    padding: 13px 15px 13px 20px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #25282E;
}
textarea:active, textarea:focus{
    box-shadow: 0px 0px 8px #C2E3BA;
}

.main-form input.error, .order__step input.error {
    box-shadow: 0px 0px 8px rgba(199, 25, 25, 0.2);
}


input::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #B2BBC3;
}
.object__prise-range-inputs input::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #25282E;
    opacity: 0.8;
}
textarea::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #B2BBC3;
}
.input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #B2BBC3;
}

.input::-moz-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #B2BBC3;
}

.input::-ms-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #B2BBC3;
}

@media (max-width: 480.98px) {
    input, textarea {
        font-size: 14px;
        padding: 13px 15px 13px 10px;
    }

    input::placeholder, .input::-webkit-input-placeholder, .input:-moz-placeholder, .input::-ms-input-placeholder  {
        font-size: 12px;
    }



}
