/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
input, button, textarea{
    font-family: 'Montserrat', sans-serif;
}
.main-form em.error {
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    margin-top: 0;
    top: 50px;
    padding-left: 15px;
    color: #9C220C;
}
.form__row{
    position: relative;
}
.main-form {
    &__input-group {
        width: 100%;
        height: 43px;
        margin-bottom: 30px;
        position: relative;

        input {

        }

        .req-star {
            position: absolute;
            width: 9px;
            height: 16px;
            top: -16px;
            right: 0;

        }

    }

    textarea {
        min-height: 76px;
        min-width: 100%;
    }

    .button-send {
        margin: 40px auto 0;
        width: 320px;
        height: 51px;
        background: #e60003;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        color: #F1F1F1;
        display: block;
        transition: 0.5s;

        &:hover {
            background: #9C220C;
        }
    }

    .form__row {
        margin: 0 auto;
    }
}

:focus {
    outline: 0;
}

// button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: #ccc #ccc #bbb;
	border-radius: 3px;
	background: #e6e6e6;
	color: rgba(0, 0, 0, 0.8);
	font-size: 1rem;
	line-height: 1;
	padding: .6em 1em .4em;
}

// button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    border-color: #ccc #bbb #aaa;
}

input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
}

select {
	border: 1px solid #ccc;
}

textarea {
	width: 100%;
}

.form {

	em.error {
        font-size: 12px;
        line-height: 18px;
        position: absolute;
        left: 0;
        display: inline-block;
        width: 100%;
        margin-top: 0;
        top: 44px;
        padding-left: 0;
        color: #9C220C;
        font-style: normal;
	}

}
.modal .form #rating-error.em.error {
    top: 25px;
    left: 257px;
}
.modal .form .rating-area em.error{
    top: 25px;
    left: 270px;
}

.modal .form .form_text_review em.error {
    top: 100px;
}
.modal .form .form_text_review  {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.modal .form .form_text_review em.error {
    top: inherit;
    position: relative;
    left: inherit;
    display: block;
    width: 100%;
    margin-top: -30px;
}




@media (max-width: 576.98px) {
    .modal__wrapper {
        overflow: auto;
        height: 100%;
    }
    .modal__wrapper {
        padding: 40px 0;
    }
}

@media (max-width: 480.98px) {
    .form em.error {
        top: 35px;
    }
    .object__buy-left .form em.error {
        top: 41px;
    }
    .modal .form .form-rating em.error {
        top: 45px;
        left: 0;
    }

}
