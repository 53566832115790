.modal {
    display: none;
    .modal-bg{
        display: block;
        background: rgba(229, 232, 244, 0.8);
        width: 100%;
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        overflow: auto;
        height: 100%;
    }

    &__wrapper {
        width: 557px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 80px 0;
        z-index: 1;
        .modal__wrapper {
            width: 557px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 80px 0;
            z-index: 1;
        }
    }

    &__block {
        background: #FFFFFF;
    }

    &__header {
        padding: 32px 42px;
        background: #4A5668;
        text-align: center;
        margin-bottom: 15px;
        position: relative;

        .modal-close {
            position: absolute;
            width: 17px;
            height: 17px;
            top: 12px;
            right: 12px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
    }

    &__content {
        width: 410px;
        margin: 0 auto;
    }

    &__text {
        margin-bottom: 36px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #25282E;
    }
}

.modals__modal.modal.m-success .modal__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #fff;
}
.modals__modal.modal.m-success  .modal__header {
    background: #4A5668;
}
.modal.active {
    display: block;
    width: 100%;
    //height: 100vh;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100%;
}

.modal .main-form__input-group {
    height: 100%;
    margin-bottom: 25px;

    label {
        font-size: 14px;
        line-height: 18px;
        color: #25282E;
    }

    input {
        height: 43px;
    }

    .req-star {
        top: 10px;
    }

}

.modal__form {
    padding-bottom: 50px;
}

.modal .form em.error {
    top: 65px;
}

@media (max-width: 480.98px) {

    .modal__wrapper {
        width: 90%;
    }
    .modal__content {
        width: 90%;
    }
}
