.footer {
    max-width: 1920px;
    margin: 0 auto;
    background-color: #333B47;
    position: relative;

    &__footer-top {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            z-index: 3;
            height: 1px;
            width: 1920px;
            background-color: rgba(208, 208, 208, 0.2);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__footer-botom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 45px 0;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #66707D;
    }
}

.footer-top {

    &__left-side {
        display: flex;
        margin: 25px 0;
        flex: 0 1 50%;
    }

    &__column {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 32px;
        text-transform: uppercase;

        a {
            color: #AAB9CF;
            transition: 0.5s;
            &:hover{
                color: #fff;
            }
        }

        &:last-child {
            margin: 0 0 0 75px;
        }
    }

    &__title {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #66707D;
        margin: 50px 50px 25px 0;
    }

    &__right-side {
        padding: 25px 0 25px 35px;
        border-left: 1px solid rgba(208, 208, 208, 0.2);
        position: relative;
        box-sizing: border-box;

        &:before {
            content: '';
            position: absolute;
            z-index: 3;
            height: 1px;
            width: 971px;
            background-color: rgba(208, 208, 208, 0.2);
            bottom: 120px;
            left: 0;
        }
    }

    &__social {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__social-right {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        text-align: right;
        color: #AAB9CF;
    }

    &__phone {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: #66707D;
        transition: 0.5s;
        @media (max-width: 2500.98px) and (min-width: 1024.98px) {
            &:hover {
                color: #87B7FF;
            }
        }
        @media(max-width: 1199px) {
            color: #AAB9CF;
        }
    }

    &__messengers {
        a {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #66707D;
            transition: 0.5s;
            &:first-child {
                margin: 0 40px 0 0;
            }
            svg{
                width: 26px;
                height: 22px;
                fill:#66707D;
                transition: 0.5s;
            }
            &:hover{
                color: #87B7FF;
                svg{
                    fill:#87B7FF;
                }
            }

        }

        span {
            opacity: 0.5;
            margin: 0 0 0 10px;
            @media(max-width: 1199px) {
                opacity: 1;
            }
        }
    }

    &__botom-row {
        display: flex;
        align-items: center;
        margin: 25px 0 0 0;
        width: 500px;
    }

    &__networks {
        display: flex;
        margin: 50px 0 25px 0;

        a {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: #AAB9CF;

            &:first-child {
                margin: 0 40px 0 0;
            }
            svg{
                width: 23px;
                height: 23px;
                fill: #B2BBC3;
                transition: 0.5s;
                @media(max-width: 1199px) {
                    margin-right: 10px;
                }
            }
            &:hover{
                svg {
                    fill: #e60003;
                }
            }
        }

        img {
            padding: 0 15px 0 0
        }
    }
}

.footer-botom {
    &__creators {
        display: flex;
        align-items: center;
    }

    &__support-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__art-sites {
        margin: 0 0 0 20px;
    }
}

.outside-arrow {
    position: fixed;
    right: 35px;
    bottom: 30px;
    display: inline-block;
    border: 1px solid #D0D0D0;
    z-index: 66;
    opacity: 0;
    -webkit-transition: opacity .3s ease, bottom .3s ease, -webkit-box-shadow .3s ease, -webkit-transform .5s ease;
    -o-transition: box-shadow .3s ease, transform .5s ease, opacity .3s ease, bottom .3s ease;
    transition: opacity .3s ease, bottom .3s ease, -webkit-box-shadow .3s ease, -webkit-transform .5s ease;
    transition: box-shadow .3s ease, transform .5s ease, opacity .3s ease, bottom .3s ease;
    transition: box-shadow .3s ease, transform .5s ease, opacity .3s ease, bottom .3s ease, -webkit-box-shadow .3s ease, -webkit-transform .5s ease;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);

    &:hover {
        cursor: pointer;
    }

    &__pict-arrow {
        height: 8px;
        width: 16px;
        margin: 15px 10px;
        position: relative;
        z-index: 10;
    }
}

.outside-arrow.scrolltop_visible {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

@media(max-width: 1024px) {
    .footer {
        &__footer-top {
            flex-direction: column;

            &:before {
                content: '';
                width: 500px;
            }
        }

        &__footer-botom {
            flex-direction: column;
            width: 100%;
        }
    }

    .footer-top {
        &__left-side {
            margin: 0 auto;
            width: 500px;
            flex: none;
            justify-content: space-between;
            position: relative;
            padding: 0 0 25px 0;

            &:before {
                content: '';
                position: absolute;
                z-index: 3;
                height: 1px;
                width: 500px;
                background-color: rgba(208, 208, 208, 0.2);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__column {
            &:last-child {
                margin: 0;
                text-align: right;
            }
        }

        &__title {
            margin: 50px 0 25px 0;
            display: inline-block;

            &:last-child {
                text-align: right;
            }
        }

        &__right-side {
            padding: 0;
            border-left: initial;

            &:before {
                content: '';
                display: none;
            }
        }

        &__top-row {
            max-width: 500px;
            margin: 0 auto;
            position: relative;
            padding: 0 0 25px 0;

            &:before {
                content: '';
                position: absolute;
                z-index: 3;
                height: 1px;
                width: 500px;
                background-color: rgba(208, 208, 208, 0.2);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__botom-row {
            justify-content: space-between;
            width: 500px;
            margin: 0 auto;
        }
    }
    .footer-botom {
        &__copyright {
            padding: 0;
            order: 2;
            margin: 15px 0 0 0;
        }

        &__support-text {
            align-items: flex-start;
            padding: 0;
        }

        &__creators {
            width: 500px;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media(max-width: 768px) {
    .outside-arrow {
        bottom: 70px;
        right: 20px;
    }
    .footer {
        &__footer-top {
            &:before {
                content: '';
                width: 300px;
            }
        }
    }

    .footer-top {
        &__left-side {
            width: 300px;
            flex-direction: column;

            &:before {
                content: '';
                width: 300px;
            }
        }

        &__column {
            &:last-child {
                text-align: left;
            }
        }

        &__title {
            margin: 25px 0 0 0;
        }

        &__top-row {
            max-width: 300px;

            &:before {
                content: '';
                width: 300px;
            }
        }

        &__botom-row {
            width: 100%;
            display: block;
            width: 300px;
            margin: 0 auto;
        }

        &__social {
            display: inline-block;
        }

        &__networks {
            margin: 25px 0;
        }

        &__social-right {
            text-align: left;
            margin: 30px 0 0 0;
        }
    }
    .footer-botom {
        &__support-text {
            text-align: left;
        }

        &__creators {
            width: 300px;
        }
    }
    .subtitle-param {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 100px;
    }
}
