.choices__inner {
    background-color: #fff;
    min-height: 38px;
    overflow: hidden;
    height: 38px;
}
.choices[data-type*=select-one]:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    top: 52%;
    right: 10px;
    background: url(../../icons/arrow-filt.svg);
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.choices__list--single {
    line-height: 12px;
}
