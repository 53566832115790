/*============================================================================
	#Fonts
==============================================================================*/

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/praktyk/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('/assets/praktyk/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/praktyk/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('/assets/praktyk/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Montserrat';
    src: url('/assets/praktyk/fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('/assets/praktyk/fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/praktyk/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('/assets/praktyk/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/praktyk/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('/assets/praktyk/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/praktyk/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('/assets/praktyk/fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

