
// Varriables
// ====================================================================

// Fonts 

$fontPrimary : 'Montserrat', sans-serif;
$fontSecondary : 'Montserrat', sans-serif;

$medium : 500;
$semiBold : 600;
$bold : 700;


// Colors

$themeColorPrimary : #000000;
$themeColorSecondary : #000000;

$fontColorPrimary : #262626;
$fontColorSecondary : #c7babb;

$headerFontColor : #b9a3a4;

$colorF5 : #f5f5f5;

$body: #222222;
$body-dark: #030320;

$blue : #004592;
$blue-h : #0045ce;
$dark-blue : #0d0b30;
// $blue-h : #94b5ff;

$sky-blue : #739dff;

$yellow : #f7e952;

$red : #ed1439;

$orange : #ff5436;

// Fonts


