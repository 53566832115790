/*============================================================================
	#Global
==============================================================================*/
html {
	box-sizing: border-box;
	height: 100%;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background: white;
	height: 100%;
    font-family: 'Montserrat', sans-serif;
    animation: 0.5s;
    color: #25282E;
    overflow: auto;
}

.no-scroll{
    overflow: hidden;
    position: relative;
}
.no-scroll-popup{
    //position: static;
    //height: auto;
    overflow-y: hidden;
    margin-right: 17px;
    //position: fixed;
    @media (max-width: 1199.98px) {
        position: relative;
        height: inherit;
        overflow: hidden;
    }

}
.background-active{
    position: relative;
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f6f8ff;
        content: '';
        z-index: 5;
    }
}
img {
	height: auto;
	max-width: 100%;
}

svg {
	vertical-align: top;
}
.wrapper {
    margin: 0 auto;
    max-width: 1920px;
}
.container {
    max-width: 1140px;
    padding: 0;
}
.lazy-background {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2P4//vHfwAJ4QPym4ubMgAAAABJRU5ErkJggg==) !important;
    background-repeat: repeat !important;
}
.root{
    margin-top: 110px;
}
@media (max-width: 1199.98px) {
    .container {
        padding: 0 15px;
    }
    .root {
        margin-top: 80px;
    }

}
/* ======= services, delivery styles*/
.page-start-wrapper{
    max-width: 1140px;
    margin: 0 auto;
    padding: 109px 0 0 0;
    @media(max-width:1200px){
        padding: 109px 15px 0 15px;
    }
    @media(max-width:1024px){
        padding: 67px 15px 0 15px;
    }
}
.services-list {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    li{
        padding: 0 0 10px 0;
        text-indent: 15px;
        position: relative;
        &:last-child{
            padding: 0;
        }
        &:before{
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #AA2A11;
        }
    }
}

.typography-description{
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #25282E;
    }
    h1{
        font-weight: 600;
        font-size: 36px;
        color: #FFFFFF;
        @media(max-width:812px){
            line-height: 42px;
        }
        @media(max-width: 576px){
            font-size: 26px;
            line-height: 30px;
        }
    }
    h2{
        @media(max-width: 576px){
            font-size: 24px;
            line-height: 32px;
        }
    }
    h3{
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #000000;
        @media(max-width: 576px){
            font-size: 24px;
            line-height: 32px;
        }
    }
    h4{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #25282E;
    }
    h5{
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #4A5668;
    }
}
/* description================================ */
.description {
    margin: 30px 0 0 0;
    &__top-title {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 167px;
    }

    &__title-text {
        position: relative;
        z-index: 2;
    }

    &__background-pict {
        position: absolute;
        top: 0;
        right: -151px;
        width: 1681px;
        height: 167px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__bottom-title {
        display: flex;
        position: relative;
    }

    &__people-pict {
        position: absolute;
        top: 0;
        left:-390px;
        width: 780px;
        height: 395px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__little-column {
        max-width: 585px;
        min-height: 315px;
        margin: 80px 20px 0 470px;
        p{
            margin: 30px 0 0 0;
            &:first-child{
                margin: 0;
            }
        }
    }
}
/* ========================================================*/
/* ways-payment=================================== */
.ways-payment {
    display: flex;
    justify-content: space-between;
    margin: 80px 0;
    &__long-column {
        position: relative;
        z-index: 2;
        h3{
            margin: 0 0 40px 0;
        }
        h4{
            margin:0 0 15px 0;
        }
        p{
            margin: 0 0 30px 0;
            &:last-child{
                margin: 0;
            }
        }
    }

    &__wrapper{
        max-width: 520px;
        padding: 0 15px 0 0;
    }

    &__pict-column {
        height: 829px;
        width: 562px;
        position: relative;
        left: 40px;
    }

    &__big-pict {
        max-width: 482px;
        max-height: 629px;
        position: relative;
        z-index: 3;
        left: 85px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__small-pict {
        max-width: 300px;
        position: absolute;
        z-index: 2;
        bottom: -200px;
        left: -80px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__background-logo{
        position:absolute;
        top: 70px;
        right: -165px;
        width: 146px;
        height: 480px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
/* =============================================== */
/* top-block====================================== */
.services {
    &__top-block {
        position: relative;
        padding: 30px 0 60px 0;
        min-height: 395px;
        box-sizing: content-box;
    }
}
.top-block {
    &__content {
        display: flex;
    }

    &__left-column {
        max-width: 390px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background-image: url(/assets/praktyk/images/services/darkpict.png);
            background-repeat: no-repeat;
            width: 781px;
            height: 167px;
        }
        }

    &__title {
        position: relative;
        z-index: 2;
        line-height: 120%;
        min-height: 167px;
        display: flex;
        margin: 0;
            &_title-text{
                align-items: center;
                max-width: 390px;
            }
    }

    &__description {
        position: relative;
        z-index: 2;
        margin: 35px 0 0 0;
        p{
            &:first-child{
                margin: 0 0 35px 0;
            }
        }
    }

    &__right-column {
        position: relative;
    }

    &__image{
        position: absolute;
        left: 0;
        top: 0;
        height: 395px;
        width: 620px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 3;
        }
    }

    &__background-image{
        position: absolute;
        bottom: 0;
        right: -140px;
    }
}
/* =============================================== */
@media(max-width:1440px){
    .ways-payment{
        &__background-logo{
            right: -155px;
        }
    }
}
@media(max-width:1439px){
    .description {
        &__people-pict {
            left: -150px;
            margin: 0 auto;
            max-height: 395px;
        }

        &__little-column {
            margin: 50px auto;
            min-height: 100%;
            padding: 0 0 0 650px;
            box-sizing: content-box;
        }

    }
    .ways-payment{
        &__background-logo{
            display: none;
        }
        &__pict-column{
            padding: 0 55px 0 0;
        }
    }
    .top-block{
        &__background-image{
            display: none;
        }
    }
}

@media(max-width:1230px){
    .ways-payment{
        &__pict-column{
            left: 0;
        }
    }
}

@media(max-width:1140px){
    .description {
        &__little-column {
            margin: 20px auto;
        }
    }
}

@media(max-width:1023px){
    .description {
        &__bottom-title {
            flex-direction: column;
        }

        &__people-pict {
            position: inherit;
            left: 0;
            order: 2;
            width: 100%;
            height: 100%;
            max-width: 585px;
        }

        &__little-column {
            padding: 0;
        }

        &__title-text {
            width: 585px;
            margin: 0 auto;
        }
    }
    .services{
        &__top-block {
            padding: 0 0 60px 0;
        }
    }
    .ways-payment {
        margin: 50px 0;
        &__wrapper{
            max-width: 585px;
        }

        &__big-pict {
            display: none;
        }

        &__pict-column {
            display: none;
        }

        &__background-logo{
            position:absolute;
            right: 0;
        }
        &__long-column{
            margin: 0 auto;
        }
    }
}
@media(max-width: 768px){
    .top-block{
        min-height: 100%;
        &__left-column {
            max-width: 100%;
            &:after{
                content: '';
                right: -15px;
            }
            }
        &__right-column {
            display: none;
        }
    }
}
/* ======= */
